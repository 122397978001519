@import '../../../../styles/variables.scss';

.block {
    margin-bottom: 16px;
}
.cell {
    height: 78px;
    padding: 0 24px;
}
.padd {
    padding: 0 24px;
}
.card_line {
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */

    letter-spacing: -0.45px;

    color: #242427;
}
.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 37px 24px 24px;
    background-color: $blocks-bg-color;
    span {
        @include title4;
        color: $font-dark;
    }
    .editBtn,
    .discardBtn,
    .confirmBtn {
        @include title5;
        color: $btn-color;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
        &:hover {
            color: $btn-color-hover;
        }
    }
    .discardBtn {
        margin-right: 26px;
        @include soft-text;
        color: $soft-color;
        transition: color 0.3s;
        &:hover {
            color: $btn-cancel-color;
        }
    }
}
.rolesGridWrapper {
    font-family: 'Work Sans', serif;

    .avatar {
        width: 51px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-transform: uppercase;
        background-color: #9eb6ff;
        margin-right: 10px;
        color: #3d6eff;
    }
    .rolesGrid {
        padding: 0 25px;
        display: grid;
        height: 78px;
        grid-template-columns: 1fr 1fr 1fr 1fr 2fr 60px;
        background-color: #eaecf1;
        background-color: #fafafd;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.45px;
        color: #242427;
        &:nth-child(odd) {
        }

        .rolesItem {
            display: flex;
            align-items: center;
            font-style: normal;
            &:last-of-type {
                margin-left: 25px;
            }
        }
        &:first-child {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 26px;
            /* identical to box height, or 162% */

            letter-spacing: -0.45px;
            text-transform: uppercase;

            /* primar/space blue */

            color: #08133c;
        }
    }
}

.inviteCoworkerModal {
    width: 347px;

    .title {
        @include title3;
        margin-bottom: 32px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 32px;
    }
    .text {
        @include regular-text;
        margin-bottom: 50px;
    }
    .input {
        width: 100%;
        background-color: transparent;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        padding: 10px 0 24px;
        outline: none;

        letter-spacing: -0.45px;

        color: #08133c;
        &:disabled {
            color: #929195;
        }
    }
    .line {
        width: 100%;
        background-color: #d8d8d8;
        height: 1px;
        margin: 0 0 24px;
        // margin: 24px 0 24px ;
    }
    button {
        height: 52px;
        width: 100%;
        font-size: 16px;
        line-height: 26px;
    }
}

.text_button {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    /* identical to box height, or 186% */
    color: #3d6eff;
    &.text_button_red {
        color: #ff0000;
    }
}
.empty_text {
    white-space: nowrap;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    /* identical to box height, or 162% */

    letter-spacing: -0.45px;

    /* grayscale/1HR gray */

    color: #929195;
}
.line {
    background: #d8d8d8;
    color: #d8d8d8;
    margin: 24px 0;
}
.textarea {
    background-color: transparent;
    width: 100%;
    border: none;
    resize: none;
    height: 78px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    /* identical to box height, or 150% */

    letter-spacing: -0.4px;

    color: #929195;
}
.receipt_block {
    .text {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* or 162% */
        padding: 25px 0;

        letter-spacing: -0.45px;

        color: #242427;
    }
}
.priceTableCont {
    margin-top: 100px;
    margin-bottom: 100px;
}
