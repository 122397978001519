@import '../../../styles/variables.scss';

.profileNav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 23px 0 23px 0;
    width: 100%;
    background-color: #fff;
    box-shadow: 0 -3px 14px 0 rgba(245, 245, 245, 0.5);
    position: fixed;
    bottom: 0;
    left: 0;
    z-index: 200;
    @media (min-width: 768px) {
        justify-content: space-between;
        position: relative;
        padding: 23px 179px 23px 87px;
    }

    .brandImg {
        width: 395px;
        height: auto;
        position: absolute;
        top: 100%;
        right: 30px;
    }

    .nav {
        display: flex;
        align-items: center;
    }

    a {
        @extend %soft-icon-styles;
        color: #242427;
        display: inline-block;
        width: 26px;
        height: 26px;
        margin-right: 52px;
        transition: color 0.3s;
        svg {
            transition: fill 0.3s;
            path {
                transition: stroke 0.3s;
            }
        }
        &.active,
        &:hover {
            color: $link-active-color;
            svg {
                fill: $link-active-color;
                path {
                    stroke: $link-active-color;
                }
            }
        }
    }

    .profile {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        .notificationsPopup {
            background: #ffffff;
            border: 0.5px solid #eaecf1;
            position: absolute;
            right: 12px;
            top: calc(100% + 10px);
            box-sizing: border-box;
            box-shadow: 0px 1px 54px -11px rgba(70, 78, 101, 0.2);
            border-radius: 8px;
            width: 382px;
            max-height: 500px;
            overflow-y: scroll;
            min-height: 186px;
        }
        .more {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 125%;
            /* identical to box height, or 18px */

            letter-spacing: -0.45px;

            /* primar/hr90 blue */

            color: #4d7aff;
            margin: 8px 24px 16px;
            display: flex;
            justify-content: flex-end;
            cursor: pointer;
        }
        .emptyNotificationsMessage {
            width: 100%;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            position: absolute;
            left: 0;
            top: 0;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 600;
            font-size: 14px;
            line-height: 125%;
            /* identical to box height, or 18px */

            letter-spacing: -0.45px;

            /* grayscale/1HR gray */

            color: #929195;
        }
        .notificationsPopupItemWrapper {
            display: flex;
            justify-content: space-between;
        }
        .notificationsPopupItem {
            font-family: 'Work Sans', serif;
            padding: 24px 25px;
            position: relative;
            background-color: #fff;
            &.notificationsPopupItem_unread {
                background-color: #fafafd;
            }
            &:not(:last-child) {
                &::after {
                    content: '';
                    position: absolute;
                    background-color: #eaecf1;
                    height: 1px;
                    bottom: 0;
                    left: 24px;
                    right: 24px;
                }
            }
        }
        .notificationsPopupItemTitle {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 125%;
            color: #08133c;
            margin-bottom: 2px;
        }
        .notificationsPopupItemText {
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;
            letter-spacing: -0.45px;
            color: #929195;
            cursor: pointer;
            b {
                font-weight: 600;
                font-size: 14px;
                line-height: 125%;
                /* or 18px */

                letter-spacing: -0.45px;
                color: #4d7aff;
            }
        }

        .notificationsPopupItemTimeAgo {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 125%;
            text-align: right;
            letter-spacing: -0.45px;
            color: #929195;
            margin: 0;
        }
        .notifyBtn {
            width: 26px;
            height: 26px;
            background-color: transparent;
            outline: none;
            border: none;
            padding: 0;
            position: relative;
            @extend %soft-icon-styles;
            color: #242427;
            cursor: pointer;
            margin-right: 26px;
            transition: color 0.3s;
            &:after {
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                background-color: #ff0000;
                width: 8px;
                height: 8px;
                border-radius: 50%;
                opacity: 0;
            }
            &.notifyBtn_unread {
                &:after {
                    opacity: 1;
                }
            }
            svg {
                transition: fill 0.3s;
                path {
                    transition: stroke 0.3s;
                }
            }
            .icon {
                display: inline-block;
                width: 26px;
                height: 26px;
                svg {
                    width: 26px;
                    height: 26px;
                }
            }
            &:hover {
                color: $link-active-color;
                svg {
                    fill: $link-active-color;
                    path {
                        stroke: $link-active-color;
                    }
                }
            }
        }
        .avatarWr {
            line-height: 0;
            font-size: 0;
            .avatar {
                width: 32px;
                height: 32px;
                border-radius: 100%;
                background-color: gray;
                margin: 0;
                overflow: hidden;
                .avatarImg {
                    object-fit: cover;
                    width: 32px;
                    height: 32px;
                }
            }
        }
    }
}
