@import "../variables.scss";

.successPayment {
  height: 100vh;
  width: 100vw;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: $basic-color;
  &_textCont {
    h3 {
      color: white;
      font-family: "Poppins";
      font-style: normal;
      font-weight: 500;
      font-size: 68px;
      line-height: 78px;
      margin-bottom: 48px;
    }
    &_button {
      width: 300px;
    }
  }
  &_img {
    overflow: hidden;
    transform: rotateY(180deg);
    height: 292px;
    line-height: 0;
    position: relative;
    @media (min-width: 768px) {
      height: auto;
      position: absolute;
      transform: none;
      bottom: 0;
      left: 0;
      width: 36vw;
    }
    img {
      width: 100%;
      height: auto;
      position: absolute;
      right: 40px;
      top: 5px;
      @media (min-width: 768px) {
        position: static;
        right: auto;
        top: auto;
      }
    }
  }
}
