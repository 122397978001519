@import "../../../../../styles/variables.scss";

.presentationSlider {
  overflow: hidden;
  width: 260px;
  @media (min-width: 375px) {
    width: 314px;
  }
  @media (min-width: 768px) {
    width: auto;
  }
  .inner {
    transform: translateX(-10px);
    @media (min-width: 375px) {
      transform: translateX(-16px);
    }
    @media (min-width: 768px) {
      transform: none;
      margin-right: 34vw;
    }
    @media (min-width: 1920px) {
      margin-right: 39vw;
    }
  }
  .presentationSlide {
    width: 240px;
    height: 428px;
    border-radius: 16px;
    background-color: $blocks-bg-color;
    cursor: pointer;
    padding: 15px;
    display: flex;
    align-items: flex-end;
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    @media (min-width: 375px) {
      width: 280px;
      height: 468px;
    }
    @media (min-width: 768px) {
      width: 334px;
      height: 572px;
    }
    img {
      width: 32px;
      height: 32px;
      transition: opacity 0.3s;
    }
    .playBar {
      width: 100%;
      display: flex;
      justify-content: space-between;
      align-items: flex-end;
      .seconds {
        span {
          @include title3;
          color: #fff;
          &:last-child {
            @include title5;
          }
        }
      }
    }
    &:hover {
      img {
        opacity: 0.6;
      }
    }
  }

  .blankFrame {
    width: 334px;
    height: 572px;
    border-radius: 16px;
    background-color: $blocks-bg-color;
  }

  .progressBar {
    margin-top: 27px;
    display: none;
    @media (min-width: 768px) {
      display: block;
    }
    span {
      @include regular-text;
      color: $soft-color;
      &:first-child {
        padding-right: 72px;
        position: relative;
        &::after {
          content: "";
          display: block;
          height: 1px;
          width: 52px;
          background-color: $soft-color;
          position: absolute;
          right: 10px;
          top: 50%;
          transform: translateY(-50%);
        }
      }
    }
  }
}
