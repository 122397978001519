@import '../../../../styles/variables.scss';

.deleteVideo {
    width: 470px;
    max-width: 100%;

    .title {
        @include title3;
        margin-bottom: 32px;
    }
    .text {
        @include regular-text;
        margin-bottom: 50px;
    }
    .input {
        width: 100%;
        background-color: transparent;
        border: none;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* identical to box height, or 162% */

        letter-spacing: -0.45px;

        color: #929195;
        &:disabled {
            color: #929195;
        }
    }
    .line {
        width: 100%;
        background-color: #d8d8d8;
        height: 1px;
        margin: 24px 0;
    }
    button {
        height: 52px;
        width: 100%;
        font-size: 16px;
        line-height: 26px;
    }
    .publish_cont {
        display: flex;
        align-items: center;
        margin-bottom: 26px;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        /* identical to box height, or 162% */
        justify-content: space-between;

        letter-spacing: -0.45px;

        color: #242427;
    }
}
