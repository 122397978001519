@import "../../../../../styles/variables.scss";

.videosControl {
  position: absolute;
  right: 0;
  z-index: 200;

  .openBtn {
    height: 26px;
    margin-top: 25px;
    display: flex;
    justify-content: center;
    align-items: center;
    .circle {
      flex-shrink: 0;
      width: 8px;
      height: 8px;
      border: 2px solid #000;
      border-radius: 100%;
      position: relative;
      display: flex;
      margin: 1px;
    }
  }

  .videosMenu {
    position: absolute;
    padding: 26px 15px;
    min-width: 218px;
    right: 0;
    background-color: #fff;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    pointer-events: none;
    transition: transform 0.3s, opacity 0.3s;
    transform: translate(100px, -100px) scale(0);
    opacity: 0;

    .btn {
      display: flex;
      align-items: center;
      margin-bottom: 26px;
      &:not(:last-child):active {
        color: $btn-color-hover;
      }
      &:last-child {
        margin-bottom: 0;
        color: $btn-cancel-color;
      }
    }

    .icon {
      font-size: 26px;
      line-height: 26px;
      margin-right: 10px;
    }

    .btnName {
      @include regular-text;
    }
    &.active {
      transform: translate(0, 0) scale(1);
      opacity: 1;
      pointer-events: all;
    }
  }
}
