@import '../../../../styles/variables.scss';

.manageVideosContainer {
    padding: 0 30px 184px;
    display: flex;
    justify-content: center;
    @media (min-width: 768px) {
        padding: 0;
        display: block;
    }
}

.videosWrapper {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 10px;
    margin: 0 147px 100px 87px;
}
.newArVideo {
}

.arContainer {
    position: absolute;
    width: 30vw;
    height: 60%;
    top: 20%;
    left: 35vw;
    display: flex;
    justify-content: center;
    border-radius: 16px;
    overflow: hidden;
    @media (max-width: 768px) {
        width: 90vw;
        height: 80vh;
        bottom: 10vh;
        left: 5vw;
    }
}
.rightSide {
    display: flex;
    flex-direction: column;
    justify-content: center;
    @media (min-width: 768px) {
        width: 60%;
        min-width: 830px;
    }
}
.tabCont {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    gap: 10px;
    margin-bottom: 32px;
    margin-top: 32px;
    @media (min-width: 768px) {
        width: 60%;
        min-width: 830px;
    }
}
.tabActive {
    display: flex;
    border-radius: 30px;
    background: #3d6eff;
    padding: 16px;
    cursor: pointer;
    input {
        color: white;
    }
}
.tabNonActive {
    display: flex;
    border-radius: 30px;
    border: 1px solid #3d6eff;
    padding: 16px;
    cursor: pointer;
    input {
        color: #3d6eff;
    }
}
.plusIcon {
    color: #3d6eff;
    margin-left: 12px;
    font-size: 32px;
    font-weight: 700;
    font-family: 'Poppins';
    cursor: pointer;
}
.tabInput {
    border: none;
    background: transparent;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: normal;
    letter-spacing: -0.14px;
    font-family: 'Poppins';
}

input:focus {
    outline: none;
}

.record {
    position: absolute;
    bottom: 5%;
    left: calc((100% - 75px) / 2);
    background-color: white;
    padding: 24px;
    border-radius: 50% 50%;
    cursor: pointer;
}

.stop {
    position: absolute;
    bottom: 5%;
    left: calc((100% - 75px) / 2);
    background-color: rgba(#ffff, 0.2);
    padding: 24px;
    border-radius: 50% 50%;
    cursor: pointer;
}

.close {
    position: absolute;
    top: 2%;
    right: 2%;
    cursor: pointer;
    path {
        stroke: white;
    }
}
