@import '../../../../styles/variables.scss';

.form {
    @media (min-width: 768px) {
        width: 58%;
        min-width: 452px;
    }
    .input-group {
        @media (min-width: 768px) {
            display: flex;
            justify-content: space-between;
            & > div {
                width: 48%;
            }
        }
    }
    .checkbox-group {
        padding: 53px 0 51px;
    }
    .error-message {
        @include title5;
        text-transform: capitalize;
        color: $error-color;
        margin-bottom: 26px;
    }
    .paymentInfo {
        @include soft-text;
        margin-bottom: 50px;
    }
    .btns-group {
        div {
            margin-bottom: 26px;
            &:last-child {
                margin-bottom: 0;
                button {
                    background-color: $btn-color-2;
                    &:hover {
                        background-color: rgba($btn-color-2, 0.9);
                    }
                }
            }
        }
    }
}
