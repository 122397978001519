@import "../../../../styles/variables.scss";

.delBtnWR {
  padding: 26px 36px;
  text-align: right;
  .delBtn {
    background-color: transparent;
    outline: none;
    border: none;
    @include regular-text;
    color: $soft-color;
    cursor: pointer;
    transition: color 0.3s;
    &:hover {
      color: $btn-cancel-color;
    }
  }
}
