@import '../../../styles/variables.scss';

.navbar {
    padding: 50px 30px;
    position: absolute;
    z-index: 100;
    top: 0;
    left: 0;
    width: 100%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    @media (min-width: 768px) {
        justify-content: space-between;
        height: 78px;
        padding: 0 28px 0 52px;
    }
    @media (max-width: 768px) {
        padding: 20px 20px;
    }

    .logoWrapper {
        overflow: hidden;
        width: fit-content;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        height: 40px;
    }
    .logoLine {
        width: 6px;
        height: 100%;
        background: white;
        margin-right: 5px;
    }

    .logoLink {
        display: none;
        margin-top: 5px;
        @media (min-width: 768px) {
            display: inline-block;
        }
    }

    .wrapper {
        display: flex;
        justify-content: space-between;
    }
    .localization {
        @include title4;
        color: #fff;
        margin-left: 48px;
        position: relative;
        display: flex;
        align-items: center;
        &::before {
            content: '';
            display: block;
            width: 4px;
            height: 4px;
            background-color: $link-active-color;
            border-radius: 100%;
            position: absolute;
            bottom: 40%;
            left: -24px;
        }
        @media (min-width: 768px) {
            margin-left: 54px;
        }
        button {
            background: transparent;
            border: none;
            outline: none;
            @include title4;
            color: #fff;
            text-transform: uppercase;
            cursor: pointer;
            &.active {
                display: none;
            }
        }
    }
    .links {
        @media (max-width: 768px) {
            display: none !important;
        }
        .link {
            @include title4;
            display: inline-block;
            text-decoration: none;
            cursor: pointer;
            color: #fff;
            transition: color 0.3s;
            margin-left: 26px;
            &:hover {
                color: $link-active-color;
            }
            @media (min-width: 768px) {
                margin-left: 52px;
            }
        }
    }
    &.blackText {
        .link {
            color: #000;
        }
        .localization {
            button {
                color: #000;
            }
            @media (max-width: 768px) {
                display: none !important;
            }
        }
    }
    .burger {
        display: none;
        @media (max-width: 768px) {
            display: block;
        }
    }
}
.searchWrap {
    display: flex;
    align-items: center;
    padding-left: calc((100vw - 950px) / 2);
    position: relative;
    @media (max-width: 768px) {
        display: none !important;
    }
    .searchWrapInput {
        border: none;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        /* identical to box height, or 162% */

        /* grayscale/1HR gray */

        color: #929195;
        height: 26px;
        outline: none;
    }
    .searchResults {
        position: absolute;
        top: 50px;
        // left: 0;
        left: calc((100vw - 950px) / 2);
        max-width: 100%;
        width: 646px;
        height: 339px;
        background: #ffffff;
        /* grayscale/3HR gray */

        border: 0.5px solid #eaecf1;
        box-shadow: 0px 1px 54px -11px rgba(70, 78, 101, 0.2);
        border-radius: 8px;
        overflow: auto;
        .searchResultItem {
            position: relative;
            height: 112px;
            display: flex;
            align-items: center;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;
            padding: 0 24px;
            /* identical to box height, or 18px */

            letter-spacing: -0.45px;

            /* grayscale/1HR gray */

            color: #929195;
            &:after {
                position: absolute;
                height: 1px;
                left: 24px;
                right: 24px;
                background-color: #eaecf1;
                content: '';
                bottom: 0;
            }
            .searchResultImageWrap {
                background: #eaecf1;
                border-radius: 16px;
                width: 64px;
                min-width: 64px;
                height: 64px;
                margin-right: 15px;
            }
            .searchResultTitle {
                font-family: 'Poppins';
                font-style: normal;
                font-weight: 500;
                font-size: 16px;
                line-height: 125%;
                /* identical to box height, or 20px */

                /* primar/space blue */

                margin-bottom: 4px;
                color: #08133c;
            }
        }
    }
}
.mobileMenuWrapper {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    .mobileMenuBackground {
        position: absolute;
        background: #08133c;
        opacity: 0.4;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        z-index: 1;
    }
    .mobileMenuContent {
        z-index: 2;
        position: absolute;
        width: 100%;
        left: 0;
        bottom: 0;
        background-color: #fff;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding-top: 16px;
    }
    .mobileMenuItem {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 125%;
        color: #08133c;
        display: flex;
        height: 40px;
        align-items: center;
        justify-content: center;
        margin: 19px 0;
    }
}
