@import "../variables.scss";

.terms {
  background-color: #fafafd;
  position: relative;
  padding: 153px 30px 53px;
  @media (min-width: 768px) {
    padding: 0;
  }
  .logo-link {
    display: block;
    padding: 25px 28px 27px 52px;
    margin-bottom: 47px;
  }
  .terms-content {
    position: relative;
    @media (min-width: 768px) {
      width: 500px;
      padding-bottom: 74px;
      margin-left: 23%;
    }
    @media (min-width: 1366px) {
      margin-left: 38%;
    }
  }

  .title-1 {
    @include title1;
    margin-bottom: 53px;
    @media (min-width: 768px) {
      margin-bottom: 80px;
    }
  }
  .title-2 {
    @include title3;
    margin-bottom: 38px;
    @media (min-width: 768px) {
      @include title2;
      margin-bottom: 29px;
    }
  }
  .title-3 {
    @include title3;
    margin-top: 76px;
    margin-bottom: 24px;
  }
  .title-4 {
    @include title4;
    margin-top: 28px;
    margin-bottom: 28px;
  }

  p {
    @include regular-text;
    color: $font-dark;
  }
}
