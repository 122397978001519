@import "../variables.scss";

body {
  overflow-x: hidden;
}

a {
  text-decoration: none;
}

button {
  border: none;
  outline: none;
  background-color: transparent;
  margin: 0;
  padding: 0;
  cursor: pointer;
}

.pointer {
  cursor: pointer;
}
