@import "../../../styles/variables.scss";

.modal {
  height: 100vh;
  width: 100vw;
  background-color: rgb(36, 36, 39, 0.8);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  opacity: 0;
  pointer-events: none;
  transition: opacity 0.5s;
  z-index: -1;
  .content {
    padding: 45px 52px 52px;
    background-color: #fff;
    transform: scale(0.5);
    transition: transform 0.4s;
  }

  &.active {
    opacity: 1;
    pointer-events: all;
    z-index: 200;
    .content {
      transform: scale(1);
    }
  }
  &.videoMod {
    transition: none;
    .content {
      // transition: transform 0.2s;
      transition: none;
      padding: 0;
      background-color: #242427;
      border-radius: 16px;
    }
  }
}
