@import "../../../../styles/variables.scss";

.presentationFooter {
  padding: 25px 30px 164px;
  background-color: #fff;
  @media (min-width: 768px) {
    padding: 103px 87px 79px;
  }

  .statistic {
    @include title4;
    margin-bottom: 26px;
    @media (min-width: 768px) {
      display: none;
    }
  }
  .info {
    display: flex;
    flex-direction: column;
    @media (min-width: 768px) {
      flex-direction: row;
    }
    .infoItem {
      width: 220px;
      display: flex;
      flex-direction: column;
      margin-bottom: 28px;
      &:last-child {
        margin-bottom: 0;
      }
      @media (min-width: 768px) {
        margin-left: 12px;
      }
      .descr {
        @include title5;
        color: $soft-color;
      }
      .value {
        @include title3;
        color: $font-rare-color;
      }

      .valueGroup {
        display: flex;
        align-items: center;
        .rating {
          span {
            color: $soft-color;
            font-size: 18px;
            line-height: 18px;
            display: inline-block;
            margin: 0 5px;
          }
        }
      }
    }
  }
}
