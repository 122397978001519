@import "../../../styles/variables.scss";

.inputSelectWr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  position: relative;
  border: none;
  outline: none;
  @include soft-text;
  label {
    @include title5;
    position: absolute;
    top: 25px;
    left: 5px;
    pointer-events: none;
  }
  .value {
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include soft-text;
    width: 100%;
    padding: 53px 20px 24px 5px;
    border-bottom: 1px solid $soft-color-2;
    transition: color 0.3s;
    svg {
      flex-shrink: 0;
      margin-left: 10px;
      transition: transform 0.3s;
    }
  }

  .valuesList {
    height: 450px;
    width: 100%;
    padding: 16px 0;
    overflow: hidden;
    position: absolute;
    list-style: none;
    z-index: 1;
    top: calc(100% - 2px);
    left: 0;
    background-color: #fff;
    border-bottom: 2px solid rgba(0, 0, 0, 0.4);
    display: none;
    .item {
      margin-bottom: 2px;
      padding: 0 34px 0 14px;
      display: flex;
      align-items: center;
      &:hover {
        background: #eaecf1;
      }
      &.active {
        background: #eaecf1;
        &:after {
          content: "";
          display: block;
          width: 26px;
          height: 26px;
          background-image: url("../../../images/approve-icon.svg");
          background-repeat: no-repeat;
          background-size: contain;
          background-position: center;
          position: absolute;
          right: 16px;
        }
      }
    }
  }

  &.readOnly {
    pointer-events: none;
    .value {
      color: #929195;
      svg {
        fill: #929195;
        path {
          stroke: #929195;
        }
      }
    }
  }
  &.active {
    .valuesList {
      display: block;
    }
    .value {
      svg {
        transform: rotateX(180deg);
      }
    }
  }

  .thumbVertical {
    height: 115px !important;
    width: 2px !important;
    background-color: #eaecf1;
  }
}
