.wrapper {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 26px;
  text-align: center;
  letter-spacing: -0.45px;
  color: #929195;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding-top: 18px;
  .text {
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.45px;
    color: #929195;
  }
  .logoWrap {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .logo {
    width: 30px;
    display: block;
    margin: 2px 6px 0;
    height: 21px;
    cursor: pointer;
  }
  .email {
    margin-top: 10px;
    svg {
      width: 40px;
      height: 40px;
      g {
        fill: rgb(204, 204, 204);
      }
    }
  }
}
