@font-face {
  font-family: "Poppins";

  src: url("../../fonts/Poppins-Medium.woff2") format("woff2"),
    url("../../fonts/Poppins-Medium.woff") format("woff"),
    url("../../fonts/Poppins-Medium.ttf") format("ttf");

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";

  src: url("../../fonts/WorkSans-Regular.woff2") format("woff2"),
    url("../../fonts/WorkSans-Regular.woff") format("woff"),
    url("../../fonts/WorkSans-Regular.ttf") format("ttf");

  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";

  src: url("../../fonts/WorkSans-Medium.woff2") format("woff2"),
    url("../../fonts/WorkSans-Medium.woff") format("woff"),
    url("../../fonts/WorkSans-Medium.ttf") format("ttf");

  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Work Sans";

  src: url("../../fonts/WorkSans-SemiBold.woff2") format("woff2"),
    url("../../fonts/WorkSans-SemiBold.woff") format("woff"),
    url("../../fonts/WorkSans-SemiBold.ttf") format("ttf");

  font-weight: 600;
  font-style: normal;
  font-display: swap;
}

// icomoon ----------------------------------------------------------------

@font-face {
  font-family: "icomoon";
  src: url("../../fonts/icomoon.eot?lchu6g");
  src: url("../../fonts/icomoon.eot?lchu6g#iefix") format("embedded-opentype"),
    url("../../fonts/icomoon.ttf?lchu6g") format("truetype"),
    url("../../fonts/icomoon.woff?lchu6g") format("woff"),
    url("../../fonts/icomoon.svg?lchu6g#icomoon") format("svg");
  font-weight: normal;
  font-style: normal;
  font-display: block;
}

[class^="icon-"],
[class*=" icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "icomoon" !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-arrow_right1:before {
  content: "\e900";
}
.icon-create:before {
  content: "\e901";
}
.icon-description:before {
  content: "\e902";
}
.icon-manage_videos:before {
  content: "\e903";
}
.icon-present:before {
  content: "\e904";
}
.icon-settings:before {
  content: "\e905";
}
.icon-share1:before {
  content: "\e906";
}
.icon-camera:before {
  content: "\e907";
}
.icon-pause:before {
  content: "\e908";
}
.icon-play:before {
  content: "\e909";
}
.icon-approve:before {
  content: "\e90a";
}
.icon-arrow_down:before {
  content: "\e90b";
}
.icon-arrow_right:before {
  content: "\e90c";
}
.icon-company:before {
  content: "\e90d";
}
.icon-cross:before {
  content: "\e90e";
}
.icon-delete:before {
  content: "\e90f";
}
.icon-e-mail:before {
  content: "\e910";
}
.icon-info:before {
  content: "\e911";
}
.icon-notifications:before {
  content: "\e912";
}
.icon-options:before {
  content: "\e913";
}
.icon-password:before {
  content: "\e914";
}
.icon-presentation:before {
  content: "\e915";
}
.icon-profile:before {
  content: "\e916";
}
.icon-rename:before {
  content: "\e917";
}
.icon-share:before {
  content: "\e918";
}
.icon-star:before {
  content: "\e919";
}
