@import '../../../../styles/variables.scss';

.login {
    padding: 75px 30px 53px;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #fafafd;

    @media (min-width: 768px) {
        padding: 75px 58px;
    }

    .title {
        @include title2;
        margin-bottom: 28px;
    }

    .subtitle {
        @include title5;
        margin-bottom: 54px;
    }

    .link {
        color: $btn-color;
        text-decoration: none;
    }

    .form {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: space-between;

        @media (min-width: 768px) {
            width: 58%;
            min-width: 452px;
        }

        .error-message {
            @include title5;
            color: $error-color;
        }
        .code-in-email-message {
            @include title5;
        }
        .btns-group {
            margin-top: 53px;
            div {
                margin-bottom: 26px;
                &:last-child {
                    margin-bottom: 0;
                    button {
                        background-color: $btn-color-2;
                        &:hover {
                            background-color: rgba($btn-color-2, 0.9);
                        }
                    }
                }
            }
        }
    }

    .reminder {
        margin-top: 25px;
        text-align: right;
        button {
            outline: none;
            border: none;
            background-color: transparent;
            @include title5;
            cursor: pointer;
            transition: 0.3s;
            &:hover {
                color: $btn-color-hover;
            }
        }
    }
}
