@import "../../../styles/variables.scss";

.cookies {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 30px 14px;
  background-color: #03081c;
  .text {
    color: #fff;
    @include regular-text;
    a {
      @include secondary-text;
      text-decoration: none;
      color: #fff;
      padding-left: 15px;
      cursor: pointer;
    }
  }

  .btn-close {
    margin-left: 15px;
    border: none;
    outline: none;
    background: transparent;
    width: 16px;
    height: 16px;
    display: flex;
    align-items: center;
    cursor: pointer;
    transition: transform 0.3s;
    &:hover {
      transform: rotate(90deg);
    }
  }
}
