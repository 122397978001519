@import "../../../styles/variables.scss";

.tooltipWr {
  color: #fff;
  position: relative;
  display: inline-block;
  cursor: pointer;

  .animationBlock {
    opacity: 0;
    transition: all 0.3s;
    transform: translateY(-10px);
    pointer-events: none;
    position: absolute;
    font-size: 0;
    line-height: 0;
    z-index: 100;
    bottom: -14px;
    left: -14px;
    transform: translateY(90%);

    &.animated {
      opacity: 1;
      transform: translateY(100%);
    }

    .tooltipContent {
      padding: 12px 12px 16px 12px;
      background-color: $basic-color;
      width: max-content;
      max-width: 250px;

      text .title {
        @include title5;
        margin-bottom: 4px;
        display: inline-block;
      }
      .text {
        @include font-headers;
        font-size: 12px;
        line-height: normal;
        font-weight: 500;
        display: inline-block;
      }

      &::after {
        content: "";
        position: absolute;
        left: 14px;
        top: 0;
        transform: translateY(-100%);
        border: 14px solid transparent;
        border-bottom: 14px solid $basic-color;
      }
    }

    &.mirror {
      bottom: -14px;
      left: auto;
      right: -14px;
      transform: translateY(90%);
      .tooltipContent::after {
        left: auto;
        right: 14px;
      }
    }
    &.XIndentsOff {
      left: 0;
      &.mirror {
        left: auto;
        right: 0;
      }
    }
  }
}
