@import "../../../../styles/variables.scss";

.signUp {
  display: flex;

  .leftSide {
    display: none;
    @media (min-width: 768px) {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      flex-shrink: 0;
      background-color: $basic-color;
      min-height: 100vh;
      width: 38%;
      min-width: 552px;
      position: relative;
      .logoLink {
        display: inline-block;
        padding: 80px;
      }
      img {
        width: 100%;
        max-width: 100%;
      }
    }
  }
  .rightSide {
    padding: 75px 30px 53px;
    width: 100%;
    background-color: #fafafd;
    min-height: 100vh;
    @media (min-width: 768px) {
      // background-color: transparent;
      padding: 75px 58px;
      flex: 1;
    }
    .title {
      margin-bottom: 28px;
      @include title2;
    }
    .text {
      @include title5;
      margin-bottom: 27px;
      display: block;
      a {
        color: $link-active-color;
      }
    }

    .successBlock {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      height: 100%;
      .title {
        margin-bottom: 28px;
        @include title2;
      }
      .text {
        @include regular-text;
        margin-bottom: 25px;
        @media (min-width: 768px) {
          margin-bottom: 51px;
          width: 391px;
        }
      }
      .btnWr {
        min-width: 216px;
        max-width: 100%;
        display: inline-block;
        margin-bottom: 28px;
        @media (min-width: 768px) {
          width: 280px;
        }
      }

      .footer {
        color: #777777;
        text-align: center;
        .reset {
          @include regular-text;
          span {
            display: inline-block;
            color: $btn-color;
            cursor: pointer;
            &:focus,
            &:active {
              color: $btn-color-hover;
            }
          }
        }

        .message {
          @include regular-text;
          a {
            color: #777777;
          }
        }
        @media (min-width: 768px) {
          display: none;
        }
      }
    }
  }
}
