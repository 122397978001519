.page {
    .text {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 18px;
        line-height: 150%;
        /* or 27px */

        letter-spacing: -0.45px;

        color: #000000;
        margin-bottom: 30px;
        b,
        strong {
            font-weight: 600;
        }
    }
    .top {
        margin-top: 150px;
        @media (max-width: 768px) {
            margin-top: 120px;
        }
    }
    .container {
        max-width: 100%;
        width: 850px;
        margin: 0 auto;
        @media (max-width: 768px) {
            width: 100%;
            padding: 0 24px;
        }
    }
    .quoteBlock {
        background-color: #eaecf1;
        padding: 80px 0;
        position: relative;
        margin-bottom: 80px;
    }
    .fullWidthImgContainer {
        width: 100%;
        border-radius: 16px;
        height: 427px;
        overflow: hidden;
        margin-bottom: 64px;
        position: relative;
        &.fullWidthImgContainerInner {
            @media (max-width: 768px) {
                height: 250px;
            }
        }
        @media (max-width: 768px) {
            height: 500px;
        }
        .fullWidthImg {
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            height: 100%;
            width: 100%;
            object-fit: cover;
        }
        .fullWidthImgContent {
            height: 100%;
            padding: 30px 20px;
            position: relative;
            z-index: 1;
            display: flex;
            align-items: flex-end;
            justify-content: space-between;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            letter-spacing: -0.45px;
            color: #fafafd;
        }
        .fullWidthImgTitle {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 125%;
            /* or 31px */

            /* grayscale/4HR gray */

            color: #fafafd;
            margin-bottom: 13px;
            width: 409px;
            max-width: 100%;
            @media (max-width: 768px) {
                width: 246px;
            }
        }
        .circle {
            width: 32px;
            min-width: 32px;
            height: 32px;
            background-color: #fff;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 50%;
            overflow: hidden;
        }
    }
    .quoteText {
        font-family: 'Poppins';
        font-style: italic;
        font-weight: 400;
        font-size: 18px;
        line-height: 200%;
        color: #000000;
        width: 690px;
        max-width: 100%;
        @media (max-width: 768px) {
            width: auto;
        }
    }

    .quoteImg {
        position: absolute;
        top: 0;
        right: 100px;
        @media (max-width: 768px) {
            width: 82px;
            height: 54px;
            right: 28px;
        }
    }
    .halfsWrap {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        margin-bottom: 70px;
        @media (max-width: 768px) {
            margin-bottom: 47px;
            flex-direction: column;
        }
        .halfBlock {
            flex-basis: calc(50% - 7px);
            width: calc(50% - 7px);
            @media (max-width: 768px) {
                flex-basis: 100%;
                width: 100%;
                margin-bottom: 32px;
            }
        }
        .halfBlockImg {
            width: 100%;
            height: 364px;
            border-radius: 16px;
            background-color: #444;
            margin-bottom: 22px;
            overflow: hidden;
            @media (max-width: 768px) {
                height: 200px;
            }
            img {
                height: 100%;
                width: auto;
                min-width: 100%;
                min-height: 100%;
                object-fit: cover;
                @media (max-width: 768px) {
                    width: 100%;
                }
            }
        }
        .halfBlockTitle {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 125%;
            color: #08133c;
            min-height: 93px;
            margin-bottom: 16px;
            @media (max-width: 768px) {
                min-height: unset;
            }
        }
        .halfBlockText {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 150%;
            /* or 24px */

            letter-spacing: -0.45px;

            /* primar/space blue */

            color: #08133c;
            &:not(:last-child) {
                margin-bottom: 16px;
            }
        }
    }
    .h1title {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 68px;
        line-height: 78px;
        /* or 115% */
        margin-bottom: 60px;
        color: #000000;
        max-width: 100%;
        @media (max-width: 768px) {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 42px;
            line-height: 125%;
            margin-bottom: 48px;
        }
    }
    .smallTitle {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 125%;
        color: #000000;
        margin-bottom: 45px;
    }
}
.blogInnerBottomMargin {
    margin-bottom: 174px;
    @media (max-width: 768px) {
        margin-bottom: 80px;
    }
}
.blogInnerBottomMargin2 {
    margin-bottom: 80px;
    @media (max-width: 768px) {
        margin-bottom: 60px;
    }
}
