@import "../variables.scss";

.error {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  .text {
    @include title2;
    color: rgb(157, 0, 0);
  }
}
