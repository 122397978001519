@import "../../../../styles/variables.scss";

.sharePresentation {
  margin-top: 52px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
  button {
    @media (min-width: 768px) {
      display: inline-block;
      width: auto;
    }
  }
}
