@import '../../../../styles/variables.scss';

.table {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 30px;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}

.priceItem {
    width: 312px;
    height: 299px;
    padding: 34px 36px 29px 26px;
    background-color: #f3f3f3;
    border: 1px solid #f1f1f1;
    border-radius: 12px;
    &_cont {
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        justify-content: flex-start;
        &_info {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            height: 133px;
            &_hiddenTopDiv {
                min-height: 40px;
            }
            &_mostPopularCont {
                background-color: white;
                color: #545a69;
                padding: 2px 6px;
                border-radius: 4px;
                &_text {
                    color: #545a69;
                    font-size: 13px;
                    font-weight: 400;
                    font-family: 'Work Sans';
                }
            }
            &_title {
                font-size: 20px;
                font-weight: 700;
                font-family: 'Poppins';
                -webkit-font-smoothing: antialiased;
                color: #1a1a1a;
            }
            &_description {
                margin-top: 15px;
                color: rgba(26, 26, 26, 0.9);
                opacity: 0.5;
                -webkit-font-smoothing: antialiased;
                font-size: 14px;
                font-weight: 400;
                font-family: 'Work Sans';
            }
        }
        &_priceCont {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            justify-content: flex-start;
            width: 100%;
            gap: 20px;
            &_priceText {
                display: flex;
                align-items: center;
                justify-content: flex-start;
                &_period {
                    color: rgba(26, 26, 26, 0.9);
                    -webkit-font-smoothing: antialiased;
                    font-size: 13px;
                    font-weight: 400;
                    font-family: 'Work Sans';
                    opacity: 0.5;
                    max-width: 55px;
                    margin-left: 4px;
                }
                &_value {
                    font-size: 36px;
                    font-weight: 700;
                    font-family: 'Poppins';
                    -webkit-font-smoothing: antialiased;
                    color: #1a1a1a;
                }
            }
        }
    }
}

.buttonWrapper {
    width: 100%;
    button {
        height: 44px;
        padding: unset;
    }
}
