.blogFooter {
    .blogFooterLine {
        width: 100%;
        height: 1px;
        position: relative;
        display: block;
        &:after {
            position: absolute;
            left: 0;
            top: 0;
            right: 0;
            height: 100%;
            background-color: #000;
            opacity: 0.1;
            content: '';
            @media (max-width: 768px) {
                left: 24px;
                right: 24px;
            }
        }
    }
    .blogFooterWrapper {
        padding: 25px 0 70px;
        display: flex;
        justify-content: flex-end;
        @media (max-width: 768px) {
            flex-direction: column;
            align-items: center;
        }
    }
    .blogFooterColumn {
        @media (max-width: 768px) {
            display: flex;
            flex-direction: column;
            align-items: center;
            margin-bottom: 32px;
        }
    }
    .blogFooterColumnTitle {
        margin-bottom: 27px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 16px;
        line-height: 26px;
        color: #242427;
        @media (max-width: 768px) {
            margin-bottom: 8px;
        }
    }
    .blogFooterColumnItem {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        margin-right: 70px;
        letter-spacing: -0.45px;

        color: #242427;
        display: flex;
        align-items: center;
        @media (max-width: 768px) {
            margin-right: 0;
        }
    }
    .blogFooterSocialIconWrap {
        width: 23px;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    .container {
        max-width: 100%;
        width: 850px;
        margin: 0 auto;
    }
    .copyright {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.45px;
        margin-top: 23px;
    }
}
