@import "../../../styles/variables.scss";

.btn {
  @include btn-text;
  display: block;
  position: relative;
  padding: 13px 35px;
  text-align: center;
  width: 100%;
  border: none;
  outline: none;
  border-radius: 6px;
  color: #fff;
  background-color: $btn-color;
  cursor: pointer;
  text-decoration: none;
  transition: background 0.3s, color 0.3s;
  @media (min-width: 768px) {
    border-radius: 4px;
    padding: 15px 35px;
  }

  &:hover,
  &:focus,
  &:active {
    background-color: $btn-color-hover;
  }

  &.disabled {
    opacity: 0.5;
    pointer-events: none;
  }
  &.withIcon {
    padding: 13px 35px 13px 50px;
    @media (min-width: 768px) {
      padding: 15px 35px 15px 50px;
    }
    img {
      position: absolute;
      max-width: 36px;
      max-height: 36px;
      left: 10px;
      top: 50%;
      transform: translateY(-50%);
    }
  }
  &.modText {
    padding: 0;
    background-color: transparent;
    &:hover {
      color: $btn-color-hover;
    }
  }
}
