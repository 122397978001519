@import '../../../../styles/variables.scss';

.logo {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid $border-color;
    padding: 25px 36px 25px 24px;
    min-height: 131px;
    .imgCont {
        display: flex;
        flex-direction: column;
        span {
            @include title4;
            margin-bottom: 1px;
        }
        .imgWrapper {
            height: 52px;
            width: 52px;
            overflow: hidden;
            border-radius: 50%;
            display: flex;
            align-items: center;
            justify-content: center;
            img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
            }
        }
        img {
            max-width: 120px;
            height: auto;
        }
    }
    .logoManipulation {
        text-align: right;
        label {
            @include regular-text;
            display: block;
            margin-top: 26px;
            color: $btn-color;
            cursor: pointer;
            &:hover {
                color: $btn-color-hover;
            }
        }
        button {
            border: none;
            outline: none;
            background-color: transparent;
            @include regular-text;
            color: $btn-cancel-color;
            cursor: pointer;
        }
        .err {
            @include font-headers;
            font-size: 12px;
            line-height: 14px;
            margin-top: 5px;
            color: $error-color;
        }
    }
}
