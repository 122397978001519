@import "../../../styles/variables.scss";

.input-wr {
  margin-bottom: 26px;
  &:last-child {
    margin-bottom: 0;
  }
  @media (min-width: 768px) {
    margin-bottom: 0;
  }
  label {
    @include regular-text;
    display: flex;
    align-items: center;
    cursor: pointer;
    position: relative;
    padding-left: 45px;
    @media (min-width: 768px) {
      padding-left: 31px;
    }
    &::before {
      content: "";
      display: block;
      width: 26px;
      height: 26px;
      position: absolute;
      left: 0;
      border: 1px solid $btn-color;
      border-radius: 2px;
      background-image: url("../../../images/approve-icons-2.svg");
      background-color: transparent;
      background-repeat: no-repeat;
      background-position: -26px;
      @media (min-width: 768px) {
        background-image: url("../../../images/icon-approve.svg");
        width: 16px;
        height: 16px;
        background-position: -16px;
      }
    }
    &.active::before {
      background-color: $btn-color;
      background-position: center;
    }
  }

  .input {
    width: 26px;
    height: 26px;
    opacity: 0;
    margin: 0;
    padding: 0;
    position: absolute;
    left: 0;
    cursor: pointer;
    @media (min-width: 768px) {
      width: 16px;
      height: 16px;
    }
  }

  &.invalid {
    color: $error-color;
    label {
      &::before {
        border: 1px solid $error-color;
      }
    }
  }
}
