@import "../variables.scss";

.company-settings-form,
.contacts-set-form,
.profile-settings-form,
.notifications-settings-form {
  padding-bottom: 78px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
  .inputs {
    padding: 0 30px;

    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(2, calc((100% - 15px) / 2));
      grid-column-gap: 15px;
      padding: 0 36px 0 20px;
    }
  }
  input {
    background-color: transparent;
  }
  .profile-set-inputs {
    margin-bottom: 52px;
  }
  .notification-set-inputs {
    margin-top: 22px;
    &_input {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 20px;
      border-bottom: 1px solid #d8d8d8;
      padding: 50px 0px;
      label {
        font-family: "Poppins", sans-serif;
        font-style: normal;
        font-size: 14px;
        line-height: 26px;
        font-weight: 500;
      }
      > div {
        margin-left: 20px;
      }
    }
  }
  .set-form-err {
    margin-top: 10px;
    text-align: right;
    @include soft-text;
    font-size: 12px;
    color: $error-color;
  }
}

.notifications-settings-form {
  .inputs {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: repeat(3, calc((100% - 15px) / 3));
      grid-column-gap: 15px;
      padding: 0 36px 0 20px;
      justify-content: space-between;
    }
  }
}

.profile-settings-form {
  padding-bottom: 0;
}
