@import "../../../../styles/variables.scss";

.companySetNotification {
  width: 347px;

  .title {
    @include title3;
    margin-bottom: 32px;
  }
  .text {
    @include regular-text;
    margin-bottom: 50px;
    span {
      @include secondary-text;
    }
  }
  button {
    width: 180px;
  }
}
