@import "../../../../../styles/variables.scss";

.slideControlBox {
  width: 100%;
  height: 100%;
  border-radius: 16px;
  background-color: rgba(0, 0, 0, 0.65);
  position: absolute;
  top: 0;
  left: 0;
  z-index: 100;
  opacity: 0;
  transition: opacity 0.3s;
  cursor: pointer;
  display: none;
  @media (min-width: 768px) {
    display: block;
  }
  &:hover {
    opacity: 1;
  }

  .btns {
    width: 100%;
    display: flex;
    justify-content: space-between;
    padding: 10px;
  }

  .btnShare,
  .btnDelete {
    border: none;
    outline: none;
    background-color: transparent;
    margin: 0;
    padding: 0;
    cursor: pointer;
    span {
      color: #fff;
      font-size: 26px;
      line-height: 26px;
      transition: color 0.3s;
    }
    &:hover {
      span {
        color: $btn-color-hover;
      }
    }
  }
}
