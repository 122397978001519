.block {
    background: #08133c;
    padding: 40px 0;
    padding-right: 32px;
    padding-left: 180px;
    position: relative;
    overflow: hidden;
    margin: 20px 0;
    border-radius: 20px;
    @media (max-width: 768px) {
        padding-left: 28px;
        padding-right: 28px;
        padding-top: 172px;
    }
}
.flex {
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 768px) {
        flex-direction: column;
    }
}
.title {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 125%;
    color: #ffffff;
    width: 283px;
    margin-bottom: 8px;
    @media (max-width: 768px) {
        width: auto;
    }
}
.subtitle {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 125%;

    color: #ffffff;
}
.advPic {
    position: absolute;
    left: 0;
    top: 50%;
    transform: translate(0%, -50%);
    @media (max-width: 768px) {
        top: -28px;
        transform: translate(0%, 0%);
    }
}
.buttonWrap {
    width: 216px;
    @media (max-width: 768px) {
        width: 100%;
        margin-top: 36px;
    }
}
