@import "../../../styles/variables.scss";

.tabsBlock {
  padding: 0 30px 184px;

  @media (min-width: 768px) {
    display: flex;
    padding: 0 88px 120px 62px;
  }

  .tabs {
    display: flex;
    flex-direction: column;
    flex-shrink: 0;
    width: 100%;
    @media (min-width: 768px) {
      width: 37%;
      min-width: 300px;
      max-width: 550px;
      margin-right: 21px;
    }
    .tab {
      margin-bottom: 26px;
      position: relative;
      padding-right: 20px;
      &:before {
        content: "";
        display: inline-block;
        width: 14px;
        height: 14px;
        background-image: url("../../../images/arr_right.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        right: 0;
        top: 50%;
        transform: translateY(-50%);
      }

      @media (min-width: 768px) {
        margin-bottom: 0;
        padding: 24px 26px;
        cursor: pointer;
        border-bottom: 1px solid $border-color;
        transition: background 0.3s;
        &:before {
          display: none;
        }
      }
      .tabName {
        transition: color 0.3s;
        @include title3;
        color: #000;
      }
      &.active {
        @media (min-width: 768px) {
          background-color: $btn-color;
          .tabName {
            color: #fff;
          }
        }
      }
      &.disabled {
        pointer-events: none;
        opacity: 0.2;
        .tabName {
          color: #000;
        }
      }
      &:hover {
        @media (min-width: 768px) {
          background-color: $btn-color-hover;
          .tabName {
            color: #fff;
          }
        }
      }
      &.tabSmall {
        @media (max-width: 767px) {
          .tabName {
            @include title4;
          }
        }
      }
    }
  }
  .content {
    flex: 1;
    .component {
      display: none;
    }
    .active {
      display: block;
    }
  }

  .mobileContent {
    .btnBack {
      padding: 52px 30px 20px 70px;
      width: 100%;
      text-align: left;
      @include title3;
      position: relative;
      &:before {
        content: "";
        display: inline-block;
        width: 32px;
        height: 32px;
        background-image: url("../../../images/arrow-back.svg");
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        position: absolute;
        left: 30px;
      }
    }
    position: fixed;
    width: 100%;
    top: 0;
    left: 0;
    z-index: 500;
    background-color: #fafafd;
    height: 100vh;
    overflow: auto;
    transition: transform 0.5s;
    transform: translateX(-100%);
    &.active {
      transform: translateX(0);
    }
  }
}
