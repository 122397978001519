@import '../../../../../styles/variables.scss';

.shareVideoModal {
    width: 570px;
    max-width: 100%;
    font-family: 'Work Sans';

    .title {
        @include title3;
        margin-bottom: 32px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 32px;
    }
    .text {
        @include regular-text;
        margin-bottom: 50px;
    }
    .input {
        width: 100%;
        background-color: transparent;
        border: none;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        padding: 10px 0 24px;
        outline: none;

        letter-spacing: -0.45px;

        color: #08133c;
        &:disabled {
            color: #929195;
        }
    }
    .line {
        width: 100%;
        background-color: #d8d8d8;
        height: 1px;
        margin: 24px 0;
    }
    button {
        font-size: 16px;
        line-height: 26px;
    }
}
.change_role_modal {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 100%;
    width: 232px;
    .change_role_modal_item {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        padding: 0 24px;
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 125%;
        /* identical to box height, or 20px */
        position: relative;
        color: #000000;
        &:not(:last-child) {
            &:after {
                background-color: #eaecf1;
                position: absolute;
                left: 24px;
                right: 24px;
                bottom: 0;
                height: 1px;
                content: '';
            }
        }
    }
}
.text_frame {
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.45px;
    color: #929195;
    padding: 24px 0 0;
    width: 100%;
    word-break: break-all;
}
.flex_c {
    display: flex;
    align-items: center;
}
.flex_between {
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.button {
    height: 34px;
    font-family: 'Work Sans';
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;

    text-align: center;
    letter-spacing: -0.45px;
    justify-content: center;
    padding: 0 14px;

    color: #fafafd;
    display: flex;
    align-items: center;
    background: #3d6eff;
    border-radius: 70px;
}
