@import "../variables.scss";

.login {
  &-page {
    @media (min-width: 768px) {
      display: grid;
      grid-template-columns: 39% 61%;
      height: 100vh;
      position: relative;
    }
  }

  &-aside {
    height: auto;
    background-color: $basic-color;
    background-image: url(../../images/brand_image.png);
    background-position: 0 125%;
    background-size: 30vw;
    background-repeat: no-repeat;
    .logo-link {
      display: block;
      padding: 80px;
    }
  }
}
