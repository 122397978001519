@import "../variables.scss";

.video-container {
  width: 280px;
  height: 430px;
  border-radius: 16px;
  position: relative;
  display: flex;
  align-items: center;
  @media (min-width: 768px) {
    width: 358px;
    height: 598px;
  }
  .video-label {
    position: absolute;
    width: 100%;
    top: -54px;
    color: #fff;
    @include title3;
    display: flex;
    align-items: center;
    justify-content: space-between;
    svg {
      cursor: pointer;
      transition: transform 0.3s;
      path {
        transition: stroke 0.3s;
      }
      &:hover {
        transform: rotate(90deg);
        path {
          stroke: #fff;
        }
      }
    }
  }

  .video-wrapper {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    display: flex;
    align-items: center;
    & > div {
      display: flex;
      align-items: center;
    }
  }

  .react-player__preview {
    border-radius: 16px;
    @media (min-width: 768px) {
      height: 598px !important;
    }
  }

  .react-player__shadow {
    width: 64px;
    height: 64px;
    background: #fff !important;
    border-radius: 100%;
  }

  .react-player__play-icon {
    border-color: transparent transparent transparent black !important;
    border-width: 12px 0px 12px 20px !important;
  }

  video {
    border-radius: 16px;
  }
  iframe {
    border-radius: 16px;
  }
}
