@import "../../../../styles/variables.scss";

.appLink {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  height: 100%;
  padding: 26px 26px 52px;
  position: relative;
  border-radius: 16px;
  color: $soft-color-2;
  align-items: center;
  background: linear-gradient(153deg, #9d9d9d 1%, #7a7a8c 95%);
  &::before {
    content: "";
    display: block;
    width: 100%;
    height: 100%;
    position: absolute;
    top: 0;
    left: 0;
    background-image: url("../../../../images/icons-camera.svg");
    background-repeat: no-repeat;
    background-position: center;
  }
  img {
    width: 172px;
  }
  span {
    display: block;
    text-align: center;
    @include title5;
  }
}
