// colors -------------------------------------------------------------------------------------------------

$basic-color: #08133c;
$font-basic-color: #000000;
$font-dark: #242427;
$font-rare-color: #3d6eff;
$link-active-color: #3d6eff;
$btn-color: #3d6eff;
$btn-color-2: #007ab4;
$btn-color-hover: #537dfb;
$btn-cancel-color: #ff0000;
$soft-color: #929195;
$soft-color-2: #d8d8d8;
$blocks-bg-color: #eaecf1;
$border-color: #ebebeb;
$error-color: #ff0000;
$font-warning: #ff0000;

// fonts -------------------------------------------------------------------------------------------------

@mixin font-basic {
  font-family: "Work Sans", sans-serif;
  font-style: normal;
}

@mixin font-headers {
  font-family: "Poppins", sans-serif;
  font-style: normal;
}

//text-styles -------------------------------------------------------------------------------------------------

@mixin regular-text {
  @include font-basic;
  font-weight: 400;
  letter-spacing: -0.45px;
  font-size: 16px;
  line-height: 26px;
}

@mixin secondary-text {
  @include font-basic;
  font-weight: 600;
  letter-spacing: -0.45px;
  font-size: 16px;
  line-height: 26px;
}

@mixin rare-text {
  @include font-basic;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
  letter-spacing: -0.45px;
}

@mixin title1 {
  @include font-headers;
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
  @media (min-width: 768px) {
    font-size: 68px;
    line-height: 78px;
  }
}

@mixin title2 {
  @include font-headers;
  font-size: 42px;
  line-height: 52px;
  font-weight: 500;
}

@mixin title3 {
  @include font-headers;
  font-size: 25px;
  line-height: 26px;
  font-weight: 500;
}
@mixin title4 {
  @include font-headers;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}
@mixin title5 {
  @include font-headers;
  font-size: 14px;
  line-height: 26px;
  font-weight: 500;
}

@mixin btn-text {
  font-family: "Poppins", sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 26px;
}

@mixin soft-text {
  @include font-headers;
  font-weight: 500;
  font-size: 14px;
  line-height: 26px;
}

//icon-styles -------------------------------------------------------------------------------------------------
%soft-icon-styles {
  font-size: 26px;
  line-height: 26px;
  color: $soft-color;
}

@mixin font-size($font-size, $line-height: normal, $letter-spacing: normal) {
  font-size: #{$font-size}px;

  @if $line-height==normal {
    line-height: normal;
  } @else {
    line-height: #{$line-height}px;
  }

  @if $letter-spacing==normal {
    letter-spacing: normal;
  } @else {
    letter-spacing: #{$letter-spacing}px;
  }
}
