.presentation-slider,
.manage-videos-slider {
  .swiper {
    overflow: visible;
    .swiper-slide {
      width: auto !important;
    }
  }
}

.presentation-slider {
  @media (max-width: 768px) {
    .swiper-slide-prev {
      transform: scale(0.8);
    }
    .swiper-slide {
      transition: all 0.3s;
      z-index: 100;
      z-index: 3;
    }
    .next {
      opacity: 0;
      z-index: -1;
      pointer-events: none;
      input {
        opacity: 0;
      }
    }
  }
  @media (max-width: 374px) {
    .next-1 {
      transform: translateX(-220px) scale(0.92);
      opacity: 0.5;
    }
    .next-2 {
      transform: translateX(-440px) scale(0.82);
      opacity: 0.5;
    }
  }
  @media (min-width: 375px) and (max-width: 767px) {
    .next-1 {
      transform: translateX(-252px) scale(0.92);
      opacity: 0.5;
    }
    .next-2 {
      transform: translateX(-502px) scale(0.82);
      opacity: 0.5;
    }
  }
}

.manage-videos-slider {
  .swiper-slide {
    transition: all 0.3s;
    z-index: 100;
    z-index: 3;
  }
  .mod-slide {
    opacity: 0;
    z-index: -1;
    pointer-events: none;
    input {
      opacity: 0;
    }
  }

  @media (max-width: 767px) {
    .swiper-slide-prev {
      transform: scale(0.8);
    }
  }

  .before-mod-slide {
    z-index: 2;
  }
  .mod-slide-1 {
    transform: translateX(-220px) scale(0.92);
    opacity: 0.5;
  }
  .mod-slide-2 {
    transform: translateX(-440px) scale(0.82);
    opacity: 0.5;
  }

  @media (min-width: 375px) {
    .mod-slide-1 {
      transform: translateX(-252px) scale(0.92);
      opacity: 0.5;
    }
    .mod-slide-2 {
      transform: translateX(-502px) scale(0.82);
      opacity: 0.5;
    }
  }

  @media (min-width: 768px) {
    .mod-slide-1 {
      transform: translateX(-210px) scale(0.92);
      opacity: 0.5;
    }
    .mod-slide-2 {
      transform: translateX(-417px) scale(0.82);
      opacity: 0.5;
    }
    .mod-slide-3 {
      transform: translateX(-699px) scale(0);
      opacity: 0;
    }
  }
}
