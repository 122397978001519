@import "../../../../styles/variables.scss";

.head {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 24px 37px 24px 24px;
  background-color: $blocks-bg-color;
  span {
    @include title4;
    color: $font-dark;
  }
  .editBtn,
  .discardBtn,
  .confirmBtn {
    @include title5;
    color: $btn-color;
    cursor: pointer;
    background-color: transparent;
    outline: none;
    border: none;
    &:hover {
      color: $btn-color-hover;
    }
  }
  .discardBtn {
    margin-right: 26px;
    @include soft-text;
    color: $soft-color;
    transition: color 0.3s;
    &:hover {
      color: $btn-cancel-color;
    }
  }
}
