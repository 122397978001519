@import "../../../../styles/variables.scss";

.wrapper {
  background-color: #eaecf1;
  padding: 28px 0 32px;
  margin-bottom: 12px;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  .title {
    display: flex;
    align-items: center;
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 25px;
    line-height: 26px;
    /* identical to box height, or 104% */
    margin-bottom: 16px;
    color: #000000;
  }
  .subtitle {
    margin-bottom: 18px;
    font-family: "Work Sans";
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 26px;
    letter-spacing: -0.45px;

    color: #242427;
  }
  .button {
    font-family: "Poppins";
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    border: none;
    box-shadow: none;
    /* identical to box height, or 186% */

    /* primar/hr90 blue */

    color: #4d7aff;
    &.button_red {
      color: #ff0000;
    }
  }
  .logo {
    position: absolute;
    bottom: 10px;
    right: 10px;
  }
}
