@import '../../../styles/variables.scss';

.presentationContainer {
    .inner {
        display: flex;
        justify-content: space-between;
        flex-direction: column;
        padding: 52px 30px 52px;
        @media (min-width: 768px) {
            flex-direction: row;
            padding: 130px 0 77px 87px;
        }
    }
    .leftSide {
        @media (min-width: 768px) {
            width: 33%;
            min-width: 451px;
        }
    }

    .rightSide {
        display: flex;
        flex-direction: column;
        justify-content: center;
        @media (min-width: 768px) {
            width: 60%;
            min-width: 830px;
        }
    }
    .tabCont {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        gap: 10px;
        margin-bottom: 32px;
        margin-left: 72px;
        @media (min-width: 768px) {
            width: 60%;
            min-width: 830px;
        }
    }
    .tabActive {
        display: flex;
        border-radius: 30px;
        background: #3d6eff;
        padding: 16px;
        color: white;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.14px;
        font-family: 'Poppins';
        cursor: pointer;
    }
    .tabNonActive {
        display: flex;
        border-radius: 30px;
        border: 1px solid #3d6eff;
        padding: 16px;
        color: #3d6eff;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
        line-height: normal;
        letter-spacing: -0.14px;
        font-family: 'Poppins';
        cursor: pointer;
    }
}
