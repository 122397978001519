@import "../../../styles/variables.scss";

.inputWr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid $soft-color-2;
  position: relative;
  @include soft-text;

  .icon {
    @extend %soft-icon-styles;
    &.valid {
      color: $link-active-color;
    }
  }
  .input {
    @include soft-text;
    width: 100%;
    border: none;
    outline: none;
    padding: 27px 20px 24px 5px;
    background: transparent;

    &::placeholder {
      @include soft-text;
      color: $soft-color;
    }
  }
  &.readOnly {
    .input {
      color: #929195;
    }
  }
  &.invalid {
    color: $font-basic-color;
    border-bottom: 1px solid $error-color;
    .icon {
      color: $error-color;
    }
  }
  .err {
    font-size: 12px;
    color: $error-color;
    position: absolute;
    bottom: 0;
    right: 0;
    pointer-events: none;
  }
}
