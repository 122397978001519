@import "../../../styles/variables.scss";

.formInput {
  display: flex;
  justify-content: space-between;
  align-items: center;

  position: relative;
  @include soft-text;
  label {
    @include title5;
    position: absolute;
    top: 25px;
    left: 5px;
  }

  input {
    @include soft-text;
    width: 100%;
    border: none;
    outline: none;
    padding: 53px 20px 24px 5px;
    border-bottom: 1px solid $soft-color-2;
    transition: color 0.3s;

    &::placeholder {
      @include soft-text;
      color: $soft-color;
    }
  }
  input[readonly] {
    color: #929195;
    pointer-events: none;
  }

  .error,
  .customError {
    font-size: 12px;
    color: $error-color;
    position: absolute;
    bottom: 0;
    right: 0;
    display: none;
  }
  .customError {
    display: block;
  }

  input:invalid[focused="true"] {
    color: $font-basic-color;
    border-bottom: 1px solid $error-color;
  }

  input:invalid[focused="true"] ~ .error {
    display: block;
  }

  input:focus {
    border-bottom: 1px solid $font-dark;
  }
}
