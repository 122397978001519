@import "../../../../styles/variables.scss";

.manageAccountBlock {
  margin-top: 26px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 30px;
  padding-bottom: 78px;
  @media (min-width: 768px) {
    padding-bottom: 0;
  }
  button {
    @include title5;
    color: $btn-cancel-color;
    margin-bottom: 26px;

    &:first-child {
      color: $btn-color;
      transition: color 0.3s;
      &:hover {
        color: $btn-color-hover;
      }
    }
    &:last-child {
      margin-bottom: 0;
    }
  }
}
