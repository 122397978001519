@import '../../../../styles/variables.scss';

.head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 24px 37px 24px 24px;
    background-color: $blocks-bg-color;
    span {
        @include title4;
        color: $font-dark;
    }
    .editBtn,
    .discardBtn,
    .confirmBtn {
        @include title5;
        color: $btn-color;
        cursor: pointer;
        background-color: transparent;
        outline: none;
        border: none;
        &:hover {
            color: $btn-color-hover;
        }
    }
    .discardBtn {
        margin-right: 26px;
        @include soft-text;
        color: $soft-color;
        transition: color 0.3s;
        &:hover {
            color: $btn-cancel-color;
        }
    }
}
.rolesGridWrapper {
    font-family: 'Work Sans', serif;

    .avatar {
        width: 51px;
        height: 51px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
        text-transform: uppercase;
        background-color: #9eb6ff;
        margin-right: 10px;
        color: #3d6eff;
    }
    .rolesGrid {
        padding: 0 25px;
        display: grid;
        height: 78px;
        grid-template-columns: 1fr 1fr 1fr 40px;
        background-color: #eaecf1;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        letter-spacing: -0.45px;
        color: #242427;
        &:nth-child(odd) {
            background-color: #fafafd;
        }

        .rolesItem {
            display: flex;
            align-items: center;
            font-style: normal;
            position: relative;
        }
        &:first-child {
            font-style: normal;
            font-weight: 400;
            font-size: 16px;
            line-height: 26px;
            letter-spacing: -0.45px;
            color: #929195 !important;
        }
    }
}

.inviteCoworkerModal {
    width: 347px;

    .title {
        @include title3;
        margin-bottom: 32px;
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 500;
        font-size: 25px;
        line-height: 26px;
        color: #000000;
        margin-bottom: 32px;
    }
    .text {
        @include regular-text;
        margin-bottom: 50px;
    }
    .input {
        width: 100%;
        background-color: transparent;
        border: none;
        border-bottom: 1px solid #929195;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        padding: 30px 0 5px;
        outline: none;

        letter-spacing: -0.45px;

        color: #08133c;
        &:disabled {
            color: #929195;
        }
    }
    .delimiter {
        height: 40px;
    }
    .errorMessage {
        p {
            @include title5;
            text-transform: capitalize;
            color: $error-color;
        }
        margin-bottom: 26px;
    }
    .line {
        width: 100%;
        background-color: #d8d8d8;
        height: 1px;
        margin: 0 0 24px;
        // margin: 24px 0 24px ;
    }
    button {
        height: 52px;
        width: 100%;
        font-size: 16px;
        line-height: 26px;
    }
    .rolesItem {
        margin-top: 30px;
        width: 30%;
        position: relative;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-bottom: 1px solid #929195;
        span {
            font-size: 16px;
        }
    }
    .change_role_modal {
        z-index: 100;
    }
    &_success {
        display: flex;
        justify-content: center;
        align-items: center;
        h2 {
            color: #3d6eff;
            font-family: 'Work Sans';
            font-size: 36px;
        }
    }
}
.change_role_modal {
    background-color: #fff;
    position: absolute;
    left: 0;
    top: 100%;
    width: 232px;
    .change_role_modal_item {
        cursor: pointer;
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 500;
        padding: 0 24px;
        display: flex;
        height: 60px;
        align-items: center;
        justify-content: space-between;
        font-size: 16px;
        line-height: 125%;
        /* identical to box height, or 20px */
        position: relative;
        color: #000000;
        &:not(:last-child) {
            &:after {
                background-color: #eaecf1;
                position: absolute;
                left: 24px;
                right: 24px;
                bottom: 0;
                height: 1px;
                content: '';
            }
        }
    }
}
