@import "../../../styles/variables.scss";

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.title {
  @include font-headers;
  @include font-size(42, 52);
  margin-bottom: 28px;
}

.subtitle {
  @include font-headers;
  margin-bottom: 58px;
}

.confirmNumber {
  @include font-size(68, 78);
  vertical-align: middle;
  text-align: center;
  width: 102px;
  height: 130px;
  margin-right: 15px;
  padding: 21px 10px;
  background-color: #eaecf1;
  border-radius: 8px;
}
