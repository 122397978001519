@import '../../../../../styles/variables.scss';

.manageVideosSlider {
    position: relative;
    @media (min-width: 768px) {
        padding: 0 0 20px;
    }

    .wrapper {
        width: 260px;
        overflow: hidden;
        @media (min-width: 375px) {
            width: 314px;
        }
        @media (min-width: 768px) {
            width: 1180px;
        }
    }
    .positionBlock {
        transform: translateX(-10px);
        @media (min-width: 375px) {
            transform: translateX(-16px);
        }
        @media (min-width: 768px) {
            transform: translateX(-480px);
        }
        @media (min-width: 1920px) {
        }
    }
    .progressBar {
        margin-top: 27px;
        position: absolute;
        right: 0;
        top: -106px;
        display: none;
        @media (min-width: 768px) {
            display: block;
        }

        span {
            @include regular-text;
            color: $soft-color;
            &:first-child {
                padding-right: 72px;
                position: relative;
                &::after {
                    content: '';
                    display: block;
                    height: 1px;
                    width: 52px;
                    background-color: $soft-color;
                    position: absolute;
                    right: 10px;
                    top: 50%;
                    transform: translateY(-50%);
                }
            }
        }
    }

    .manageVideosSlide {
        .inner {
            width: 240px;
            height: 428px;
            padding: 15px;
            background-repeat: no-repeat;
            background-size: cover;
            background-position: center;
            background-color: #eaecf1;
            border-radius: 16px;
            display: flex;
            align-items: flex-end;
            position: relative;
            @media (min-width: 375px) {
                width: 280px;
                height: 468px;
            }
            @media (min-width: 768px) {
                width: 218px;
                height: 364px;
            }

            .playBar {
                width: 100%;
                display: flex;
                justify-content: space-between;
                align-items: flex-end;
                .seconds {
                    span {
                        @include title3;
                        color: #fff;
                        &:last-child {
                            @include title5;
                        }
                    }
                }
                position: relative;
                z-index: 200;
            }
        }
    }

    .slideInputLabel {
        display: flex;
        justify-content: space-between;
        align-items: center;
        position: relative;
        @include soft-text;
        .input {
            @include title4;
            width: 100%;
            border: none;
            outline: none;
            padding: 25px 0 27px 0;
            background: transparent;
            caret-color: #3d6eff;
            transition: transform 0.3s, opacity 0.3s;
            border-bottom: 1px solid transparent;

            &::placeholder {
                @include soft-text;
                color: $soft-color;
            }
            &.focused {
                font-size: 14px;
                position: relative;
                transform: translateY(-24px);
                border-bottom: 1px solid #242427;
            }
        }

        .error {
            @include title5;
            color: $error-color;
            position: absolute;
            bottom: 0;
            left: 0;
        }
    }
}
