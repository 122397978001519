@import "../../../styles/variables.scss";

.footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 25px 30px;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
  @media (min-width: 768px) {
    flex-direction: row;
    height: 10vh;
    padding: 0 30px;
  }
  ul {
    @include font-headers;
    @include font-size(14, 26);
    color: $font-dark;
    text-align: center;

    li {
      display: inline-block;
      margin-right: 32px;
      position: relative;

      &:after {
        content: "";
        position: absolute;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background-color: #1c54e7;
        right: -16px;
        top: 45%;
      }
      &:last-child {
        margin-right: none;

        &:after {
          content: none;
        }
      }

      a {
        color: $font-dark;
      }
    }
  }
}

.pop-up {
  position: fixed;
  top: 0;
  top: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 200;
}

.funded-btn {
  margin-top: 25px;
  width: 160px;
  @media (min-width: 768px) {
    margin-top: 0;
  }
}

.info-image {
  width: 100%;
  @media (min-width: 768px) {
    width: 60%;
  }
}
