@import "../../../styles/variables.scss";

.userInfo {
  padding: 50px 0 24px;
  margin: 0 30px 25px;
  border-bottom: 1px solid $soft-color-2;
  display: flex;
  justify-content: space-between;

  .name {
    color: #000;
    @include title3;
    margin-bottom: 29px;
    text-transform: capitalize;
  }

  .email {
    color: $font-dark;
    @include regular-text;
    word-break: break-all;
  }

  .col:first-child {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  .col:last-child {
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }

  .notifiBtn {
    margin-bottom: 29px;
    width: 26px;
    height: 26px;
  }
  .logOutBtn {
    @include title5;
    color: $btn-cancel-color;
  }
}
