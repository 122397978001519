.wrap {
  border: 2px solid #d8d8d8;
  border-radius: 40px;
  width: 42px;
  height: 26px;
  overflow: hidden;
  position: relative;
  cursor: pointer;
  transition: 0.1s ease-out;
  &.switcherChecked {
    border-color: #3d6eff;
  }
  .switcher {
    position: absolute;
    top: 50%;
    left: 2px;
    transform: translate(0, -50%);
    background: #d8d8d8;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    overflow: hidden;
    transition: 0.1s ease-out;
    &.switcherChecked {
      transform: translate(15px, -50%);
      background: #3d6eff;
    }
  }
}
