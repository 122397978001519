@import "../../../../styles/variables.scss";
.hero {
  background-color: $basic-color;
  position: relative;
  @media (min-width: 768px) {
    height: 100vh;
    min-height: 800px;
    display: flex;
    align-items: center;
  }

  .content {
    padding: 153px 30px 0 30px;
    color: #fff;
    @media (min-width: 768px) {
      padding: 100px 19vw 100px 38vw;
    }
  }

  .title {
    @include title1;
  }

  .text {
    @include regular-text;
    margin: 30px 0 25px 0;
    max-width: 429px;
    @media (min-width: 768px) {
      margin: 58px 0 51px 0;
    }
  }

  .btnWr {
    min-width: 218px;
    display: inline-block;
    @media (min-width: 768px) {
      width: 300px;
    }
  }

  .img {
    overflow: hidden;
    transform: rotateY(180deg);
    height: 292px;
    line-height: 0;
    position: relative;
    @media (min-width: 768px) {
      height: auto;
      position: absolute;
      transform: none;
      bottom: 0;
      left: 0;
      width: 36vw;
    }
    img {
      width: 100%;
      height: auto;
      position: absolute;
      right: 40px;
      top: 5px;
      @media (min-width: 768px) {
        position: static;
        right: auto;
        top: auto;
      }
    }
  }
}
