@import "../../../../styles/variables.scss";

.inputsGroup {
  form {
    padding-bottom: 23px;
    @media (min-width: 768px) {
      padding-top: 23px;
      padding-bottom: 51px;
    }
  }

  .iconMod {
    path {
      stroke: $soft-color;
    }
  }

  .titleFild,
  .descrFild {
    resize: none;
    width: 100%;
    max-width: 100%;
    border: none;
    outline: none;
    background: transparent;
    caret-color: #3d6eff;
    overflow: hidden;
    color: #000;
    &::placeholder {
      transition: color 0.125s;
      color: $soft-color;
    }

    &:focus {
      &::placeholder {
        color: $soft-color-2;
      }
    }
  }

  .titleFild {
    @include title2;
    margin-bottom: 30px;
  }

  .descrFild {
    @include regular-text;
    color: $soft-color;
  }
}
