@import "../../../styles/variables.scss";

.upgradeBlock {
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 78px;
  z-index: 200;
  button {
    border-radius: 0;
  }
}
