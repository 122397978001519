@import '../../styles/variables.scss';

.profileLayout {
    background-color: #fafafd;
    position: relative;
    min-height: 100vh;
    .container {
        padding: 23px 62px;
        padding-top: 110px;
        @media (max-width: 768px) {
            padding: 23px 30px;
            padding-top: 20px;
        }
    }

    .title {
        @include title2;
        padding: 0 24px;
        padding-left: 0;

        max-width: 500px;
        @media (min-width: 768px) {
            white-space: unset;
        }
    }
    .status {
        /* small text */

        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 14px;
        line-height: 125%;
        /* identical to box height, or 18px */
        padding-right: 100px;
        letter-spacing: -0.45px;
        margin: 0;

        @media (min-width: 768px) {
            padding-right: 0;
        }

        color: #4d7aff;
    }
    .title_flex_wrap {
        display: flex;
        align-items: center;
        position: relative;
    }
    .flex_between {
        display: flex;
        margin-bottom: 24px;
        align-items: center;
        justify-content: space-between;
    }
    .description {
        font-family: 'Work Sans';
        font-style: normal;
        font-weight: 400;
        font-size: 16px;
        line-height: 26px;
        max-width: 100%;
        width: 650px;

        letter-spacing: -0.45px;

        color: #929195;
    }
    .projectsPopup {
        position: absolute;
        background: #ffffff;
        border: 0.5px solid #eaecf1;
        box-shadow: 0px 1px 54px -11px rgba(70, 78, 101, 0.2);
        border-radius: 8px;
        top: 70px;
        left: 0;
        max-width: 100%;
        z-index: 1;
        width: 457px;
        max-height: 400px;
        overflow: auto;
        .projectsPopup_item {
            padding: 35px 30px;
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;
            /* identical to box height, or 18px */

            letter-spacing: -0.45px;

            /* grayscale/1HR gray */

            color: #929195;
            display: flex;
            align-items: center;
            justify-content: space-between;
        }
        .projectsPopup_item_title {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 500;
            font-size: 25px;
            line-height: 125%;
            /* or 31px */

            color: #000000;
        }
        .draftText {
            font-family: 'Work Sans';
            font-style: normal;
            font-weight: 400;
            font-size: 14px;
            line-height: 125%;
            /* identical to box height, or 18px */

            letter-spacing: -0.45px;

            /* primar/hr90 blue */

            color: #4d7aff;
        }
    }
}
